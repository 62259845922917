<template>
  <a
    :href="dashboardUrl"
    class="flex items-center gap-x-4 px-8 py-6 text-lg font-semibold leading-6 text-gray-900 bg-comeen-blue-light"
  >
    <span class="sr-only">Admin dashboard link</span>
    <span aria-hidden="true">{{ t('ui.access_admin_dashboard') }}</span>
    <FontAwesomeIcon
      :icon="faArrowRight"
      class="h-5 w-5 shrink-0"
      aria-hidden="true"
    />
  </a>
</template>

<script setup>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faArrowRight } from '@fortawesome/pro-regular-svg-icons'

const { t } = useI18n()

const dashboardUrl = useDashboardUrl()
</script>
